/**
 * Joel & Tanya
 * April 24, 2021
 */

console.log("💖 Joel & Tanya - April 24, 2021");

import $ from "jquery";

$(function () {
  // Link all posts to their sources
  $(".posts .post").each((i, elem) => {
    const $post = $(elem);
    const url = $post.find("a:last-child").attr("href");

    if (url) {
      $post
        .on("click", () => {
          window.location.href = url;
        })
        .addClass("clickable");
    }
  });
});
